<template>
    <Layout>
        <!-- <PageHeader :title="title"/> -->
        <div class="d-flex justify-content-between align-items-center">
            <BulletinBoardTabs />
            <button
                :class="`btn btn-primary ${loading ? 'disabled' : ''}`"
                @click="goToForm()">
                {{ $t('create popup') }}
            </button>
        </div>
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                <b-form inline>
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                <td class="text-center hidden">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            lang="en"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <button
                                        :class="`btn btn-danger ${
                                            loading ? 'disabled' : ''
                                        }`"
                                        @click="resetFilter()">
                                        {{ $t('reset') }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </b-form>
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="5"
                        :rows="50"
                        :td_small_class="`d-none`"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">
                                    {{ $t('subject') }}
                                </th>
                                <th scope="col">
                                    {{ $t('user') }}
                                </th>
                                <th scope="col">
                                    {{ $t('created at') }}
                                </th>
                                <th scope="col">
                                    {{ $t('action') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="r in data.list" :key="r._id">
                                <td>
                                    {{ r.subject }}
                                </td>
                                <td class="text-center">
                                    <div
                                        class="fw-normal d-flex align-items-center justify-content-center">
                                        <i
                                            class="bx bxs-user font-size-13 text-primary"></i>
                                        {{ r.user.username }}
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-center">
                                    <div class="button-items">
                                        <button
                                            class="btn btn-outline-info btn-sm"
                                            @click="goToForm(r._id)">
                                            {{ $t('edit') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-danger btn-sm"
                                            @click="remove(r)">
                                            {{ $t('delete') }}
                                        </button>
                                    </div>
                                </td>
                                <td class="mobile">
                                    <div class="cell-card">
                                        <div>
                                            {{ r.subject }}
                                        </div>
                                        <div>
                                            <div>
                                                <div
                                                    class="fw-normal d-flex align-items-center justify-content-center">
                                                    <i
                                                        class="bx bxs-user font-size-13 text-primary"></i>
                                                    {{ r.user.username }}
                                                </div>
                                            </div>
                                            <div class="text-muted">
                                                {{
                                                    dateYmD(r.created_at, true)
                                                }}
                                            </div>
                                            <div class="button-items">
                                                <button
                                                    class="btn btn-outline-info btn-sm"
                                                    @click="goToForm(r._id)">
                                                    {{ $t('edit') }}
                                                </button>
                                                <button
                                                    class="btn btn-outline-danger btn-sm"
                                                    @click="remove(r)">
                                                    {{ $t('delete') }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination :data="data" @emitPage="initList" />
            </div>
        </div>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import Swal from 'sweetalert2';
import BulletinBoardTabs from '@/components/bulletin-board-tabs';
import { mapActions, mapGetters } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        BulletinBoardTabs
    },
    mixins: [datetime, number, random, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                keyword: '',
                status: '',
                from: new Date(this.firstDateOftheMonth()),
                to: new Date(),
            },
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            points_modal: false,
            loading: false,
        };
    },
    watch: {
        filters: {
            handler() {
                var queryObj = new Object();
                if (this.filters['status'])
                    queryObj['status'] = this.filters['status'];
                queryObj['unread'] = this.filters['unread'];
                queryObj['from'] = this.dateYmD(this.filters['from']);
                queryObj['to'] = this.dateYmD(this.filters['to']);
                this.$router.replace({ query: queryObj });
                this.initList(1);
            },
            deep: true,
        },
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('popup', {
            popupGetList: 'getList',
            popupDelete: 'delete',
        }),
        readQuery() {
            this.filters['status'] = this.$route.query['status'] || null;
            this.filters['unread'] = Boolean(this.$route.query['unread']);

            if (this.$route.query.today) {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else if (this.$route.query['from'] && this.$route.query['to']) {
                this.filters['from'] = new Date(this.$route.query['from']);
                this.filters['to'] = new Date(this.$route.query['to']);
            }
        },
        async initList(p) {
            this.resetScroll();
            var pl = {
                page_no: p ? p : 1,
                read: this.filters.unread ? 0 : 1,
                status: this.filters.status,
                from: this.dateYmD(this.filters.from) + ` 00:00:00`,
                to: this.dateYmD(this.filters.to) + ` 23:59:59`,
                search: this.filters.keyword ? this.filters.keyword : '',
            };
            this.loading = true;
            const data = await this.popupGetList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;
            this.loading = false;
        },

        async remove(r) {
            if (!this.isAuthorized()) return;
            const confirmed = await Swal.fire({
                title: `${this.$t('are you sure')}?`,
                html: `${this.$t('delete this popup')}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f1b44c',
                cancelButtonColor: '#74788d',
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('confirm'),
            }).then((result) => {
                return result.value;
            });

            if (confirmed) {
                const res = await this.popupDelete({ id: r._id });

                if (res) {
                    Swal.fire({
                        icon: res.success ? 'success' : 'error',
                        title: res.success
                            ? this.$t('popup has been deleted')
                            : this.$t('deleting failed'),
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (res.success) {
                        this.initList(1);
                    }
                }
            }
        },
        resetFilter() {
            this.filters.from = new Date(this.firstDateOftheMonth());
            this.filters.to = new Date();
        },
        goToForm(id) {
            var w = window.open(
                `/popup/form${id ? '?id=' + id : ''}`,
                'popup-form',
                'width=' + 1020 + ',height=' + (screen.height - 200)
            );
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
        },
    },
    mounted() {
        // this.readQuery()
        this.initList(1);
        window.addEventListener('message', (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.data == 'reload-events') {
                this.initList(this.data.current_page);
            }
        });
    },
};
</script>
